import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import Image4 from "../../../assets/images/campaign-prediction/undraw_book_reading_kx9s.svg"
import Image2 from "../../../assets/images/campaign-prediction/undraw_data_trends_b0wg.svg"
import Image3 from "../../../assets/images/campaign-prediction/undraw_invest_88iw.svg"
import Image1 from "../../../assets/images/campaign-prediction/undraw_in_progress_ql66.svg"
import "./scss/index.scss"

const Steps = () => {
  const intl = useIntl()

  return (
    <section className="py-8 steps bg-light">
      <Container>
        <Row className="mb-5 pb-5">
          <Col className="text-center">
            <h2>{intl.formatMessage({ defaultMessage: "How does it work?" })}</h2>
            <span className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "Sell your event tickets as usual. After only a few orders, we will give you first sales forecasts. The optional dynamic pricing also allows you to adjust ticket prices based on demand. This will increase your revenue.",
              })}
            </span>
          </Col>
        </Row>

        <Row className="bg-white">
          <Col xs={12} className="mt-n5 text-center">
            <Badge className="my-3 whatsapp-bg-color">
              <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step-by-step" })}</p>
            </Badge>
          </Col>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image1} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 1" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage: "You start the ticket sale, we take care of the rest.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Our automated system captures and analyzes all available data with state-of-the-art machine learning.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={Image2} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 2" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Get accurate forecasts of your ticket sales and make better decisions.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage: "First predictions are possible after the first 50 orders.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image3} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 3" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Enable smart pricing and our AI maximizes your ticket revenue through dynamic pricing.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "A similar approach can be found when booking airline tickets or Uber rides. (This feature is optional.)",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={Image4} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 4" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "After the event is before the event. With the data collected, we can now give you recommendations for better pricing for the next event.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "The more events you do about our system, the more accurate your predictions will be.",
                })}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  )
}

export default Steps
