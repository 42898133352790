import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container, Row } from "react-bootstrap"
import Overview from "../../../assets/images/campaign-prediction/overview.jpg"

const Hero = () => {
  const intl = useIntl()

  return (
    <section className="pt-6 pt-md-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 order-md-2">
            <img className="img-fluid" src={Overview} alt="..." />
          </div>
          <div className="col-12 col-md-8 col-lg-5" data-aos="fade-right">
            {/* Heading */}
            <h1
              className="font-weight-bold"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    '<span class="text-whatsapp">Data-driven forecasts</span> for ticket sales of your event. <span class="text-whatsapp">Increase your ticket revenue</span> by up to 60%.',
                }),
              }}
            />

            {/* Text */}
            <p className="font-size-lg text-muted mb-6">
              {intl.formatMessage({
                defaultMessage:
                  "Do not rely on your gut feeling. We give you accurate forecasts and increase your revenue.",
              })}
            </p>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
