import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Hero from "../components/campaign-prediction/Hero"
import PredictionLeadGeneration from "../components/campaign-prediction/PredictionLeadGeneration"
import Preview from "../components/campaign-prediction/Preview"
import References from "../components/campaign-prediction/References"
import Steps from "../components/campaign-prediction/Steps"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Lead = () => {
  const intl = useIntl()

  return (
    <Layout noFooter>
      <SEO title={intl.formatMessage({ defaultMessage: "Predict your ticket sales" })} />
      <Hero />
      <PredictionLeadGeneration />
      <Preview />
      <Steps />
      <References />
      <PredictionLeadGeneration />
      <Footer disableCallToAction />
    </Layout>
  )
}

export default Lead
